<template>
  <v-container>
    <div class="text-center">
      <div class="icon">
        <svg height="200" width="200" xmlns="http://www.w3.org/2000/svg">
          <image
            href="https://a-prokat.storage.yandexcloud.net/1/fc581057-c08d-45fe-ac54-d96f16b5b018/404-2.png"
            height="200"
            width="200"
          />
        </svg>
      </div>
      <div class="text__head">
        <h2>Упс! Страница не найдена.</h2>
        <h3>Ошибка 404</h3>
      </div>
      <div>
        <v-btn outlined large @click="goMain">На главную</v-btn>
      </div>
      <div class="trasnparent">
        <div class="link">
          {{this.previousRoute && this.previousRoute != '/' ? 'https://app.a-prokat.com' + this.previousRoute : 'https://app.a-prokat.com' + this.$route.fullPath}}
        </div>
        <div class="overline">
        Если Вы уверены, что здесь точно должно что-то быть, то воспользуйтесь
        кнопкой обратной связи
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "Ошибка 404",
    };
  },
  data: () => ({
    previousRoute: null,
  }),
beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.previousRoute = from.path;
    });
  },
  methods: {
    goMain() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  margin: 40px auto 30px;
  transition-property: top;
  transition-duration: 2s;
  svg {
    margin-left: auto;
    filter: drop-shadow(0px 3px 5px black) drop-shadow(0px 18px 30px #1565c0);
  }
}
.link {
  font-size: 0.9em;
}
.text__head {
  margin: 50px auto 50px;
}
.trasnparent {
  opacity: 0.3;
  position: absolute;
  bottom: 5px;
  width: 320px;
  left: calc(50% - 160px);
}
.text-center {
  transition-property: margin;
  transition-duration: 1s;
}

@media (min-width: 900px) {
  .text-center {
    margin-top: 125px;
    transition-property: margin;
    transition-duration: 1s;
  }
}
@media (max-height: 610px) {
  .trasnparent {
    display: none;
  }
}
</style>